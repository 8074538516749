import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Shenzhen Starnoon Technology Co., LTD
			</title>
			<meta name={"description"} content={"Transforming Dreams into Celebrations – Elegance in Every Detail"} />
			<meta property={"og:title"} content={"Shenzhen Starnoon Technology Co., LTD"} />
			<meta property={"og:description"} content={"Transforming Dreams into Celebrations – Elegance in Every Detail"} />
			<meta property={"og:image"} content={"https://tlastpainter.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://tlastpainter.com/img/icon-7.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://tlastpainter.com/img/icon-7.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://tlastpainter.com/img/icon-7.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://tlastpainter.com/img/icon-7.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://tlastpainter.com/img/icon-7.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://tlastpainter.com/img/icon-7.jpg"} />
			<meta name={"msapplication-TileColor"} content={"https://tlastpainter.com/img/icon-7.jpg"} />
		</Helmet>
		<Components.Eader>
			<Override slot="quarklycommunityKitMenu" />
		</Components.Eader>
		<Section padding="80px 0 80px 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--dark"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Contacts{"  "}
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--dark" text-align="center">
					Embark on the path to your extraordinary event with Shenzhen Starnoon Technology Co., LTD.
				</Text>
				<Link
					href="mailto:blank?contact@tlastpainter.com"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					info@rivieracasinofun.gmail
				</Link>
				<Link
					href="tel:86-13430775834"
					text-align="center"
					color="--dark"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					86-13430775834
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--dark" text-align="center">
				Room 301, Building A, No.59 Ainan Road, Longdong Community, Baolong Street, Longgang District, Shenzhen
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});